import './style.css'
import img1 from "../assets/Irina1.jpg";
import img2 from "../assets/Irina2.jpg";
import '../animation.css'


function IrinaBogatikova() {
    return (
        <div className={'Irina'}>
            <div className={'header'}>
                <img className={'animation desc-photo'} src={img1}/>
                <img className={'animation phone-photo'} src={img2}/>
                <p className={'name animation'}><p>ИРИНА АЛЕКСАНДРОВНА</p><p>БОГАТИКОВА</p></p>
                <h3 className={'animation'}>ВЛАДЕЛЕЦ, РУКОВОДИТЕЛЬ ПРОЕКТОВ</h3>
            </div>
            <div className={'texts'}>
                <div className={'item'}>
                    <p className={'title animation'}>- Владелец группы компаний (архитектура, дизайн, декор, АРТ)</p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Образование: МАИ, МАРХИ, MosArtSchool. В профессии дизайн интерьера с 2000 г. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Топ 100 дизайнеров России.</p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Эксперт по восстановлению старого фонда (статус культурного наследия). </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Искусствовед, коллекционер, куратор выставок, галерист. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Консультант по инвестициям в искусство. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}> - Академик Международной академии современных искусств. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Почетный член Евразийского Художественного Союза, гильдия искусствоведов и арт-критиков. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Почетный член Союза Дизайнеров и Архитекторов. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Лицо проекта ВПМ «Сельское подворье РФ 2023-2063» и Руководитель Модуля «БЛАГОУСТРОЙСТВО ЖИЛЬЯ» Совета предпринимателей и инвесторов ВПМ «Сельское подворье РФ 2023-2063».</p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Партнёр Форума по креативной экономике «Уникальная Россия». </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Партнёр Центра культуры и бизнеса Италии и России. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Представитель России в международных программах по культуре и искусству. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Лауреат премии Премии LUXURY&SUCCES INTERNATIONAL AWARDS за просветительскую деятельность в области культуры и искусства. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Лауреат премии ART EXCELLENCE AWARDS 2023. («Искусство. Совершенство. Признание»). </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Серебряная медаль в номинации АРТ-ПРОЕКТ 2023 года: за курс КУЛЬТ-ПОГРУЖЕНИЕ. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Автор программы профессиональной переподготовки кадров: Дизайнер интерьера в международной Академии EDPRO. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Профессиональный преподаватель, наставник дизайнеров интерьера, декораторов, арт-консультантов. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Автор уникальных образовательных программ по истории искусств КУЛЬТ-ПОГРУЖЕНИЕ и КУЛЬТ-ИНТЕНСИВ. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Владелец закрытого Клуба ART_Q. Хозяйка интеллектуальных Салонов в Усадьбе Чёрный Лебедь. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Визионер, трендсеттер в области современного искусства и дизайна. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Почетный спикер форумов, выставок, мероприятий по темам: Архитектура, Дизайн интерьера, История искусств, Современное искусство, Инвестиции в искусство. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Организатор интеллектуально-развлекательных мероприятий по теме искусства и культуры.</p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Сооснователь Конкурса молодых художников ART IS BUSINESS: проект для выявления и поддержки молодых талантов России в области культуры, искусства и креативных индустрий. </p>
                </div>
                <div className={'item'}>
                    <p className={'title'}>- Член Рабочих групп: ГД РФ, СФ РФ, Рабочей группы «Развитие сельских территорий Российской Федерации» 2023-2063г. Комиссии по развитию креативных индустрий в Совете по финансово-промышленной и инвестиционной политике ТПП РФ. </p>
                </div>
                
                <div className={'item'}>
                    <h3 className={'title'}>Ирина Богатикова:</h3>
                    <p className='text'>"После окончания работы над проектом жаль расставаться...." - так говорят клиенты Ирины, когда наступает счастливый момент переезда в свой новый дом. И возвращаются с новыми заказами - для своих детей, друзей, рекомендуют бизнес-партнерам. 24-летний опыт работы, репутация надежного и проницательного человека, великолепное чувство вкуса и стиля, техническая грамотность и комфорт в общении - за эти качества Ирину Богатикову ценят заказчики, коллеги и партнеры по бизнесу.</p>
                </div>
                <div className={'item'}>
                    <h3 className={'title'}>Как говорят мои заказчики: "У Ирины всегда найдутся нужные люди - от винодела до финансиста".</h3>
                    <p className='text'>Офис, в котором мы работаем, находится в красивейшем историческом месте Москвы – на территории Петровского парка, в усадьбе «Черный Лебедь». Камерная обстановка территории и офиса, спокойная атмосфера среди суеты города. В разделе «Контакты» вы найдете точный адрес и фотографии наших мест.</p>
                </div>
            </div>
        </div>
    );
}

export default IrinaBogatikova;