import './main.css'
import '../animation.css'

function Main() {
    return (
        <div className={'main animation'}>
            <p>КЛУБ<br/>ИРИНЫ БОГАТИКОВОЙ</p>
        </div>
    );
}

export default Main;