import img from '../assets/aboutclub-img.jpg'
import '../animation.css'
import './aboutClub.css'

function AboutClub() {
    return (
        <div className='all-aboutClub'>
            <div className={'animation image-aboutClub'}>
                <img style={{width:'100vw'}} src={img}/>
            </div>
            <div className='texts-aboutClub'>
                <h3 className='title-aboutClub'>Дизайн-бюро «Клуб Ирины Богатиковой». </h3>
                <p className='text-aboutClub'>Компания существует с 2011 года. 
	Занимается архитектурными проектами, дизайном интерьеров, комплектацией объектов отделочными материалами и мебелью, сопровождением строительных и ремонтно-отделочных работ. 
Команда состоит из профессиональных архитекторов, дизайнеров, комплектаторов, визуализаторов и менеджера системы. 
	Основным видом деятельности компании является дизайн частных интерьеров премиального сегмента.
	Реализованные проекты публикуются в топовых СМИ, Ирина Богатикова входит в топ-100 лучших дизайнеров России. 
С 2000 года реализовано более 350 проектов: общественных объектов и частных апартаментов. 
	Особенное внимание уделяем объектам «Старого фонда» (статус культурного наследия). Мы вдохновлены тем, что сохраняем культурное наследие, бережно относимся к истории каждого здания. Обладаем профессиональными знаниями в инженерии, материаловедении, истории архитектуры и дизайна. Поэтому клиенты и партнёры нам доверяют самые сложные объекты в исторических зданиях. 
Команда дизайн-бюро Ирины Богатиковой принимала участие в реконструкции старого фонда Four Seasons Hotel MOSCOW, легендарного Дома На Набережной, Доходного дома Е. М. Орлова в Санкт-Петербурге, и многих других объектов исторического наследия Москвы и Санкт-Петербурга. 
	Наша команда разделяет философию о важном влиянии искусства на человека и его окружение. Поэтому мы активно продвигаем настоящее искусство в интерьеры и предлагаем авторский метод Ирины Богатиковой АРТ-ДИЗАЙН.
	Обучающие программы для дизайнеров интерьера также являются неотъемлемой частью деятельности Клуба Ирины Богатиковой. Более 250 специалистов повысили свою квалификацию и получили дополнительное профессиональное образование Дизайнер интерьера. 
</p>
            </div>
        </div>
    );
}

export default AboutClub;