import '../IPS/IPS.js'
import React from 'react'

const Sn = () => {
  return (
    <div className='item-IPS'>
      <h3 className='title-IPS'>Сайты и соц.сети</h3>
      <a target='_blank' href='https://www.irinabogatikova.club/  ' className='link-IPS'>Дизайн интерьера</a>
      <a target='_blank' href=' https://irinabogatikova.art/ ' className='link-IPS'>Авторский курс по истории искусств</a>
      <a target='_blank' href=' https://vk.com/clubart_q' className='link-IPS'>VK</a>
      <a target='_blank' href=' https://t.me/s/club_art_Q_by_Irina_Bogatikova ' className='link-IPS'>Телеграм</a>
      <a target='_blank' href='https://unionda.ru/irina-bogatikova ' className='link-IPS'>Официальная страница на Сайте Союз дизайнеров и архитекторов </a>
      <a target='_blank' href='https://edprodpo.com/about/teachers/24493/  ' className='link-IPS'>Страница педагога на сайте академии EDPRO  </a>
      <a target='_blank' href=' https://edprofi.online/9L44uo?fuid=184418349&utm_source=partnerlink&erid=6gJC1eHFJgUhZQyrdgfHxCdj2vc4rpZqwVrdbe218QhKQnqngxhXc ' className='link-IPS'>Авторская программа профессиональной переподготовки Дизайнер интерьера </a>
      <a target='_blank' href='https://oprovanse.ru/?ysclid=lu2skxf595459971788  ' className='link-IPS'>Специальный проект для загородного жилья «Дизайн в стиле Прованс» </a>
    </div>
  )
}

export default Sn
