import React from 'react'
import './IPS.css'

const IPS = () => {
  return (
    <div>
      <div className='item-IPS'>
        <h3 className='title-IPS'>Интервью:</h3>
        <a target='_blank' href='https://d4u.ru/m/uchimsya-u-profi/intervyu-s-dizajnerom-irinoj-bogatikovoj-roskosh-ne-v-zolote-a-v-intellekte ' className='link-IPS'>Интервью с дизайнером Ириной Богатиковой: «Роскошь не в золоте, а в интеллекте» информационный портал D4U </a>
        <a target='_blank' href='https://decornews.ru/experts/irina-bogatikova/  ' className='link-IPS'>Информационный портал  Decornews «Ирина Богатикова, интегральный дизайнер интерьера и окружающей среды»</a>
        <a target='_blank' href=' https://basicdecor.ru/blog/post/n-interview-irina-bogatikova/  ' className='link-IPS'>Basicdecor «Интервью с Ириной Богатиковой: «Строители понимают, что с дизайнером работать легче» </a>
        <a target='_blank' href='https://artocratia.ru/journal/the-shortest-way-understanding-the-world-is-through-art-interview-with-designer-and-art-consultant-irina-bogatikova ' className='link-IPS'>Artocratia — «Самый короткий путь к пониманию мира лежит через искусство»: интервью с дизайнером и арт-консультантом Ириной Богатиковой»</a>
        <a target='_blank' href='https://orpheusradio.ru/programs/tavor-v-mazhore/2022-10-07/20468-irina-bogatikova  ' className='link-IPS'>Эфир на радио «Орфей». В гостях у Йосси Тавора. Передача «Тавор в мажоре». 7.10.2022г. </a>
      </div>
      
      <div className='item-IPS'>
        <h3 className='title-IPS'>Публикации дизайн-проектов:</h3>
        <a target='_blank' href=' https://salon.ru/creators/irina-bogatikova-19672 ' className='link-IPS'><b>Издание SALON.</b> Архитектор Ирина Богатикова</a>
        <a target='_blank' href='https://dzen.ru/a/XYTUqBGNfwC0e_3R ' className='link-IPS'><b>AD Magazine Russia.</b> Двухуровневая квартира в Москве по проекту Ирины Богатиковой</a>
        <a target='_blank' href=' https://archidom.ru/specialists/17952/?sphrase_id=7765' className='link-IPS'><b>Издание АРХИДОМ.</b> Публикации проектов: «Арктика», «Квартира для молодого интеллектуала», «АРТ-Майами». Богатикова Ирина (archidom.ru)</a>
        <a target='_blank' href='https://topdesignmag.ru/interer/moskovskaya-semejnaya-kvartira-arktika-sovremennoe-iskusstvo-i-ottenki-okeana/ ' className='link-IPS'><b>Top Design Magazine.</b> Московская семейная квартира: Арктика, современное искусство и оттенки океана.</a>
        <a target='_blank' href=' https://www.calameo.com/read/007260108a8e97d55cc19 ' className='link-IPS'><b>HI HOME.</b> Май-июнь 2023. Стр.81-86 Ночная Арктика. </a>
        <a target='_blank' href=' https://mydecor.ru/interior/flats/moskovskaya-kvartira-67-m-v-ottenkakh-severnogo-siyaniya/' className='link-IPS'><b>midecor.</b> “Московская квартира в оттенках северного сияния”</a>
        <a target='_blank' href=' https://design-mate.ru/read/objects/deep-arctic-colors-in-bold-apartment-interior-project-by-irina-bogatikova' className='link-IPS'><b>DESIGN MATE.</b> Глубокие арктические цвета в смелом интерьере квартиры — проект Ирины Богатиковой.</a>
        <a target='_blank' href=' https://designboom.ru/press-about-us/' className='link-IPS'><b>DOM & Podium</b> №39-40 2023. Интерьер с обложки. АРКТИКА.</a>
        <a target='_blank' href=' https://d4u.ru/d/irina-bogatikova-394' className='link-IPS'><b>D4U.</b> Три проекта</a>
        <a target='_blank' href=' https://dzen.ru/a/ZR1wwmi7z1Vm6yeF' className='link-IPS'>Портал профессиональных дизайнеров <b>Design Chat:</b> «Арктическая» квартира в Москве по дизайну Ирины Богатиковой» </a>
        <a target='_blank' href=' https://www.pinwin.ru/profile.php?user=150321' className='link-IPS'>Победитель конкурса  <b>PinWin</b>  </a>
        <a target='_blank' href='https://www.inmyroom.ru/posts/31083-kak-dizajner-uvelichila-ploshchad-kvartiry-v-dva-raza ' className='link-IPS'><b>Портал INMYROOM</b> – «Как дизайнер увеличила площадь квартиры в два раза» </a>
      </div>
      <div className='item-IPS'>
        <h3 className='title-IPS'>Статьи и события:</h3>
        <a target='_blank' href=' https://robb.report/stil-zhizni/94633-art-konsalting-dlya-lyuksovyh-intererov/' className='link-IPS'><b>Robb Report</b> «АРТ- КОНСАЛТИНГ ДЛЯ ЛЮКСОВЫХ ИНТЕРЬЕРОВ»</a>
        <a target='_blank' href=' https://artvesti.ru/news/2023/08/01/interery-kotorye-yavlyayutsya-kollektsiej-iskusstva/ ' className='link-IPS'><b>АртВести.</b> «Интерьеры, которые являются коллекцией искусства» </a>
        <a target='_blank' href='https://tenchat.ru/media/536415-investitsii-v-buduscheye   ' className='link-IPS'><b>«РУБЛЁVKA GOLD»</b> Инвестиции в будущее. </a>
        <a target='_blank' href='https://artmaison.moscow/arkhiv-afish/personalnaya-vystavka-kollektsionera-iriny-bogatik/  ' className='link-IPS'><b>ART Maison.</b> Персональная выставка коллекционера Ирины Богатиковой "РЕЛИГИЯ МЕТАМОДЕРНА"</a>
      </div>
    </div>
  )
}

export default IPS

{/* <a target='_blank' href=' ' className='link-IPS'></a> */}